.header-button {
  background-color: #fff;
  border: solid 2px #11416c;
  color: #11416c;

}

.app-name {
  font-family: 'Roboto', sans-serif;
  font-size: 270%;
}

.letter-blue {
  color: #11416c;
}

.btn-sq-responsive {
  max-width: 95% !important;
  max-height: 95% !important;
  width: 44px !important; /* whatever width you want for mobile screen */
  height: 44px !important; /* whatever height you want for mobile screen */
  font-size:125%;
  padding: 0px;
}