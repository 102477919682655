
.app-container {
  max-width: 500px;
  height: 100vh;
  margin-left: auto;
  margin-right: auto;
  color: #11416c ;
  padding-right: var(--bs-gutter-x,.75rem);
  padding-left: var(--bs-gutter-x,.75rem);
}

.content {
  max-height: 85vh;
  overflow: auto;
  padding: 0.5rem !important;
}

/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey; 
  border-radius: 10px;
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: #11416c; 
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #0b2c49; 
}