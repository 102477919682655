.data-label {
  line-height: 1.25rem;
}

.graph-bar {
  background-color: #468B6C;
  color: #f4f3f1;
  font-weight: bolder;
}

.graph-bar.colorblind {
  background-color: #2271B2;
}