.overlay-screen {
  position: fixed;
  display: block;

  width: 100%;
  height: 100%;

  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  background-color: #071b2dad;
  z-index: 2;
}

.overlay-wrapper {
  max-width: 500px;
  margin: auto;
}

.overlay {
  background-color: #fff;
  border: 1px solid #11416c;
}

@media (min-width: 720px) {
 .overlay-wrapper {
    max-width: 85%;
  }
  /*
  .overlay-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
  } */
}