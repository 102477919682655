.credits {
  font-size: 13px;
}

a.whatsapp {
  color: #25D366;
}

.close-button{
  float: right;
  border: none;
  color: #11416c;
}