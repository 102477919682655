.keyboard-button {
  border: solid 1px #11416c;
  color: #11416c;
  font-size: 16px;
}

/* keyboard */

.letter-button {
  background-color: #fff;
  width: 8%;
  height: 44px;
}

.letter-button:disabled {
  background-color: #f2f2f2;
  color: #808080;
  border: solid 1px #808080;
}

/* ENTER/BACKSPACE */

.action-button {
  background-color: #fff;
  color: #468B6C;
  border: solid 1px #468B6C;
  padding: 5px;
  width: 20%;
}

.action-button:disabled {
  color: #AE4231;
  border: solid 1px #AE4231;
}

/* right */

.right {
  background-color: #468B6C;
  color: #f4f3f1;
}

.right.colorblind {
  background-color: #468B6C;
}

.right:disabled {
  background-color: #aad4c1 !important;
}

.right.colorblind:disabled {
  background-color: #aad4c1 !important;
}

/* wrong */

.wrong {
  background-color: #AE4231;
}

.wrong.colorblind {
  background-color: #AE4231;
}

.wrong:disabled {
  background-color: #e9b7afe6 !important;
}

.wrong.colorblind:disabled {
  background-color: #e9b7afe6 !important ;
}

/* displaced */

.displaced {
  background-color: #FFD550;
}

.displaced.colorblind {
  background-color: #FFD550;
}

.displaced:disabled {
  background-color: #ffe799e6;
}

.displaced.colorblind:disabled {
  background-color: #ffe799e6;
}

