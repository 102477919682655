.win-text {
  color: #468B6C;
}

.lose-text {
  color: #AE4231;
}

.close-button{
  float: right;
  border: none;
  color: #11416c;
  margin: 0px;
}