.letter-wrapper {
  width: 48px;
  height: 40px;
  border-radius: 2px !important;
  font-size: 20px;
  font-weight: bold;
  flex-direction: column;
}

.typing {
  border: 2px solid #AE4231;
  color: #252525;
  box-shadow: 0 0 2px #bc3326;
}

.disabled {
  border: 2px solid #11416c;
  background-color: #fff;
}

.undefined {
  border: 2px solid #11416c;
  background-color: #fff;
}

.wrong {
  box-shadow: inset 0 0 2px black;
  border: 0px !important;
  text-shadow: 0 0 3px black;
  color: #ffffee !important;
}

.wrong.colorblind {
}

.right {
  box-shadow: inset 0 0 2px black;
  border: 0px !important;
  text-shadow: 0 0 3px black;
  color: #ffffee !important;
}

.right.colorblind {
  border-radius: 3px 15px 3px !important;
}

.displaced {
  box-shadow: inset 0 0 2px black;
  border: 0px !important;
  text-shadow: 0 0 3px #AE4231;
  color: #FFFFee !important;
}

.displaced.colorblind {
  border-radius: 1rem !important;
  border: dashed 3px #11416c;
}

.wordlistError {
  border: 3px solid #AE4231;
  color: #AE4231;
  box-shadow: 0 0 10px #AE4231;
}

.wordlistError.colorblind {
  border: 3px dashed #AE4231 !important;
}

.letter-underline-active {
  width: 80%;
  margin-left: auto;
  margin-right: auto;
  border-bottom: 3px solid;
  border-radius: 1rem !important;
  animation: blinking 1s infinite;
  margin-bottom: 1px;
}

@keyframes blinking {
  0% {
    border-bottom-color: #468B6C;
  }
  33% {
    border-bottom-color: #AE4231;
  }
  66% {
    border-bottom-color: #FFD550;
  }
  100% {
    border-bottom-color: #fff;
  }
}